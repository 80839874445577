import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { fetchAllUsers, fetchUserTickets, fetchUserJobs } from './apiService';
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Box,
    Container, Grid, TablePagination, ButtonGroup, Button, IconButton, Link,Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import TaskIcon from '@mui/icons-material/Task';
import { useTheme } from '@mui/material/styles';
import Layout from './Layout';
import { isSameDay } from 'date-fns';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ActivityMap from '../utils/ActivityMap';


const Dashboard = () => {
    const { currentUser, logout } = useAuth();
    const [userJobs, setUserJobs] = useState([]);
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [userTickets, setUserTickets] = useState([]);
    const [filterStatus, setFilterStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [error, setError] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // Estado para controlar el orden de clasificación, inicializado en 'asc'
    const [showMap, setShowMap] = useState(false); // Estado para controlar la visualización del mapa

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSortOrderChange = () => {
        setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const paginatedJobs = userJobs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const obtenerDatos = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const users = await fetchAllUsers();
            setAllUsers(users);

            const loggedInUser = users.find(user => user.email === currentUser.email);
            setUserDetails(loggedInUser);

            if (loggedInUser && loggedInUser.id) {
                const [tickets, jobs] = await Promise.all([
                    fetchUserTickets(loggedInUser.id),
                    fetchUserJobs(loggedInUser.id, filterStatus)
                ]);
                setUserTickets(tickets);

                // Filtrar los trabajos según el estado
                const filteredJobs = filterStatus === 0
                    ? jobs.filter(job => isSameDay(new Date(job.date), new Date()))
                    : jobs;

                // Ordenar los trabajos según el orden seleccionado
                const sortedJobs = filteredJobs.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
                });

                setUserJobs(sortedJobs);
                setPage(0);
            }
        } catch (error) {
            console.error('Error al obtener datos:', error);
            setError('Hubo un problema al cargar los datos. Por favor, intente de nuevo.');
        } finally {
            setIsLoading(false);
        }
    }, [currentUser, filterStatus, sortOrder]);

    useEffect(() => {
        if (currentUser && currentUser.email) {
            obtenerDatos();
        }
    }, [currentUser, filterStatus, sortOrder, obtenerDatos]);

    const handleFilterChange = (event) => {
        setFilterStatus(event.target.value);
        setPage(0);
    };

    const handleSelectJob = (jobId, userId) => {
        navigate(`/job/${jobId}/${userId}`);
    };

    const formatearFecha = (fecha) => {
        const fechaObj = new Date(fecha);
        const hoy = new Date();

        if (fechaObj.toDateString() === hoy.toDateString()) {
            return `Hoy a las ${fechaObj.toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit', hour12: true })}`;
        } else {
            return fechaObj.toLocaleDateString('es-MX', { day: '2-digit', month: '2-digit', year: 'numeric' }) +
                ' a las ' +
                fechaObj.toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit', hour12: true });
        }
    };

    const generarLinkGoogleMaps = (lat, lon) => {
        return `https://www.google.com/maps?q=${lat},${lon}`;
    };

    return (
        <Layout>
            <Container>
                <Box mt={3} style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
                    <Typography variant="h5" gutterBottom>
                        Trabajos Asignados:
                        <ButtonGroup
                            variant="contained"
                            aria-label="filtro de trabajos"
                        >
                            <Button
                                onClick={() => handleFilterChange({ target: { value: 0 } })}
                                style={{
                                    backgroundColor: filterStatus === 0 ? "green" : undefined,
                                    color: filterStatus === 0 ? "white" : undefined
                                }}
                            >
                                Pendientes
                            </Button>
                            <Button
                                onClick={() => handleFilterChange({ target: { value: 1 } })}
                                style={{
                                    backgroundColor: filterStatus === 1 ? "orange" : undefined,
                                    color: filterStatus === 1 ? "white" : undefined
                                }}
                            >
                                En progreso
                            </Button>
                            <Button
                                onClick={() => handleFilterChange({ target: { value: 2 } })}
                                style={{
                                    backgroundColor: filterStatus === 2 ? "gray" : undefined,
                                    color: filterStatus === 2 ? "white" : undefined
                                }}
                            >
                                Cerrado
                            </Button>
                        </ButtonGroup>
                        <IconButton
                            onClick={handleSortOrderChange}
                            style={{ marginLeft: 10 }}
                        >
                            {sortOrder === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        </IconButton>
                        <Button
                            onClick={() => setShowMap(!showMap)}
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 10 }}
                        >
                            Mapa de Actividades
                        </Button>
                        </Typography>
                    {isLoading ? (
                        <Typography>Cargando trabajos...</Typography>
                    ) : userJobs.length > 0 ? (
                        <>
                            <List>
                                {paginatedJobs.map((job, index) => (
                                    <ListItem
                                        key={job.id}
                                        button
                                        onClick={() => handleSelectJob(job.id, userDetails.id)}
                                        style={{
                                            backgroundColor: index % 2 === 0
                                                ? theme.palette.mode === 'dark' ? theme.palette.background.level2 : '#f7f7f7'
                                                : theme.palette.background.default,
                                        }}
                                    >
                                        <ListItemIcon><TaskIcon /></ListItemIcon>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <ListItemText primary={job.title} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <ListItemText primary={formatearFecha(job.date)} />
        
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                ))}
                            </List>
                            <TablePagination
                                component="div"
                                count={userJobs.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    ) : (
                        <Typography>
                            {filterStatus === 0
                                ? "No hay trabajos asignados para hoy."
                                : "No hay trabajos asignados."}
                        </Typography>
                    )}
                </Box>
            </Container>
            <Dialog open={showMap} onClose={() => setShowMap(false)} maxWidth="lg" fullWidth>
                <DialogTitle>Mapa de Actividades</DialogTitle>
                <DialogContent>
                    <ActivityMap userId={userDetails?.id} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowMap(false)} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default React.memo(Dashboard);