import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { fetchUserJobs } from '../components/apiService';
import L from 'leaflet';
import { isSameDay } from 'date-fns';

// Configuración del icono de marcador
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const ActivityMap = ({ userId }) => {
    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userLocation, setUserLocation] = useState([31.6904, -106.4245]); // Coordenadas por defecto

    useEffect(() => {
        const obtenerTrabajos = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const jobs = await fetchUserJobs(userId);
                const today = new Date();
                const filteredJobs = jobs.filter(job => job.status === 0 && isSameDay(new Date(job.date), today));
                setJobs(filteredJobs);
            } catch (error) {
                console.error('Error al obtener trabajos:', error);
                setError('Hubo un problema al cargar los trabajos. Por favor, intente de nuevo.');
            } finally {
                setIsLoading(false);
            }
        };

        if (userId) {
            obtenerTrabajos();
        }

        // Obtener la ubicación actual del usuario
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation([position.coords.latitude, position.coords.longitude]);
                },
                (error) => {
                    console.error('Error al obtener la ubicación:', error);
                }
            );
        }
    }, [userId]);

    return (
        <div>
            {isLoading ? (
                <p>Cargando mapa...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <MapContainer center={userLocation} zoom={13} style={{ height: '500px', width: '100%' }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {jobs.map((job) => (
                        job.gpsLat && job.gpsLon && (
                            <Marker key={job.id} position={[job.gpsLat, job.gpsLon]}>
                                <Popup>
                                    {job.title}<br />{job.description}
                                </Popup>
                            </Marker>
                        )
                    ))}
                </MapContainer>
            )}
        </div>
    );
};

export default ActivityMap;