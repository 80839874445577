// httpClient.js
import { API_CONFIG } from './apiConfig';

const BASE_URL = 'https://portal.icentral.com.mx/crm/api/v1.0/';

const httpClient = async (endpoint, options = {}, apiBaseURL = BASE_URL) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-Auth-App-Key': API_CONFIG.appKey
  });

  // Añadir encabezado de contenido si hay datos en el cuerpo
  if (options.body && !(options.body instanceof FormData)) {
    options.body = JSON.stringify(options.body);
  }

  const config = {
    ...options,
    headers
  };

  const response = await fetch(`${apiBaseURL}${endpoint}`, config);
  if (!response.ok) {
    const errorBody = await response.text();
    console.error("Error response body:", errorBody);
    throw new Error(`Error en la solicitud a la API: ${errorBody}`);
  }

  const contentType = response.headers.get("Content-Type");
  if (contentType && contentType.includes("application/json")) {
    return response.json();
  } else {
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.startsWith('image/')) {
      return response.blob();
    }
    return response;
  }
};

const actualizarServicioCliente = async (serviceId, attributes) => {
    const endpoint = `clients/services/${serviceId}`;
    
    // Envolvemos los atributos en un objeto con la clave "attributes"
    const data = { attributes };
    console.log("Datos a enviar:", JSON.stringify(data, null, 2));

    const options = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-App-Key': API_CONFIG.appKey
        },
        body: JSON.stringify(data)
    };

    try {
        const response = await fetch(`${BASE_URL}${endpoint}`, options);
        const responseData = await response.text();
        console.log("Respuesta del servidor (texto):", responseData);

        if (!response.ok) {
            throw new Error(`Error en la solicitud a la API: ${responseData}`);
        }

        return JSON.parse(responseData);
    } catch (error) {
        console.error("Error en actualizarServicioCliente:", error);
        throw error;
    }
};

const obtenerServicioCliente = async (serviceId) => {
    const endpoint = `clients/services/${serviceId}`;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-App-Key': API_CONFIG.appKey
        }
    };

    try {
        const response = await fetch(`${BASE_URL}${endpoint}`, options);
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message || 'Error al obtener los datos del servicio');
        }
        return data;
    } catch (error) {
        console.error("Error en obtenerServicioCliente:", error);
        throw error;
    }
};

export default httpClient;
export { actualizarServicioCliente, obtenerServicioCliente };
