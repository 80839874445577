import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getTasks,getAllTasks, updateJobStatus, addComment, getJobComments,getClienteDetalles,getClienteService,fetchAllUsers  } from './apiService';
import { Paper,Container,Typography, Box, Card, CardContent,Button, Divider,  Snackbar, Alert,} from '@mui/material';
import Layout from './Layout';
import { format } from 'date-fns';
import axios from 'axios';
import { actualizarServicioCliente, obtenerServicioCliente } from './httpClient';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DialogsAndButtons from '../utils/DialogsAndButtons';
import StartServiceDialog from '../utils/StartServiceDialog';
import ConfirmCloseServiceDialog from '../utils/ConfirmCloseServiceDialog';
import ServiceDetails from '../utils/ServiceDetails';

const JobDetails = () => {
    const { jobId, userId } = useParams();
    const navigate = useNavigate(); 
    const [jobDetails, setJobDetails] = useState(null);
    const [comentarios, setComentarios] = useState([]);
    const [tareatask, setTareatask] = useState(null);
    const [clienteDetalles, setClienteDetalles] = useState(null);
    const [servicioDetalles, setServicioDetalles] = useState(null);
const [onuSignalByService, setOnuSignalByService] = useState({});
const [onuSignalValueByService, setOnuSignalValueByService] = useState({});
const [open, setOpen] = useState(false);
const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarSeverity, setSnackbarSeverity] = useState('success');
const [formulario, setFormulario] = useState({
    gponSerial: '',
    gponPort: '',
    onuId: '',
    secondaryEquipmentModel: '',
    splitterPort: '',
    nap: '',
    candado: ''
});
const [gponSerialConfirmation, setGponSerialConfirmation] = useState('');
const [showGponSerialConfirmation, setShowGponSerialConfirmation] = useState(false);
const [gponSerialError, setGponSerialError] = useState('');
const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
const [currentPage, setCurrentPage] = useState(1);
const [commentsPerPage] = useState(5); // Puedes ajustar este número según tus necesidades
const [openNotesDialog, setOpenNotesDialog] = useState(false);
const [additionalNote, setAdditionalNote] = useState('');
const [openStartServiceDialog, setOpenStartServiceDialog] = useState(false);


const attributeMapping = {
    gponSerial: 41,
    gponPort: 43,
    onuId: 44,
    secondaryEquipmentModel: 46,
    splitterPort: 49,
    nap: 52,
    candado: 60
};


const handleOpenStartServiceDialog = () => {
    setOpenStartServiceDialog(true);
};

const handleCloseStartServiceDialog = () => {
    setOpenStartServiceDialog(false);
};

const handleConfirmStartService = () => {
    iniciarServicio();
    setOpenStartServiceDialog(false);
};

const handleBackToTasks = () => {
    navigate('/dashboard'); // Cambia '/tasks' a la ruta correcta de tus tareas
};
    const obtenerUbicacionActual = () => {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject('La Geolocalización no está soportada por tu navegador.');
            } else {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            }
        });
    };
    
    const crearEnlaceGoogleMaps = async (gpsLat, gpsLon) => {
        try {
            const posicionActual = await obtenerUbicacionActual();
            const origenLat = posicionActual.coords.latitude;
            const origenLon = posicionActual.coords.longitude;
            const destino = `${gpsLat},${gpsLon}`;
    
            const enlace = `https://www.google.com/maps/dir/?api=1&origin=${origenLat},${origenLon}&destination=${destino}&travelmode=driving`;
            return enlace;
        } catch (error) {
            console.error('Error obteniendo la ubicación actual:', error);
            return null;
        }
    };
    
    const atributosAMostrar = ["NAP", "ONT Model","Candado","GPON Serial", "Coordenadas","Splitter Port" /* ... otros atributos deseados ... */];

    // Función para filtrar los atributos que se mostrarán
    const filtrarAtributos = (atributos) => {
        return atributos.filter((atributo) => atributosAMostrar.includes(atributo.name));
    };

    const getGPONSerialFromServiceDetails = (serviceDetails) => {
        if (serviceDetails && serviceDetails.attributes) {
          const gponSerialAttribute = serviceDetails.attributes.find(attr => attr.name === 'GPON Serial');
          return gponSerialAttribute ? gponSerialAttribute.value : null;
        }
        return null;
      };

      const obtenerServiceIds = () => {
        if (servicioDetalles && servicioDetalles.length > 0) {
            return servicioDetalles.map(servicio => servicio.id);
        }
        return [];
    };  
    const handleInputChange = (name, value) => {
        setFormulario(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === 'gponSerial') {
            validateGponSerial(value, gponSerialConfirmation);
        }
    };

    const handleGponSerialConfirmation = (value) => {
        setGponSerialConfirmation(value);
        validateGponSerial(formulario.gponSerial, value);
    };

    const validateGponSerial = (gponSerial, confirmation) => {
        if (gponSerial !== confirmation) {
            setGponSerialError('Los valores de GPON Serial no coinciden');
        } else {
            setGponSerialError('');
        }
    };

    const toggleGponSerialConfirmationVisibility = () => {
        setShowGponSerialConfirmation(!showGponSerialConfirmation);
    };

    const cargarComentarios = async () => {
        try {
            const comentariosCargados = await getJobComments(jobId);
            
            const comentariosConUsuarios = await Promise.all(comentariosCargados.map(async (comentario) => {
                try {
                    const usuarios = await fetchAllUsers();
                    const usuario = usuarios.find(user => user.id === comentario.userId);
                    
                    return { ...comentario, usuarioNombre: usuario ? usuario.username : 'Usuario Desconocido' };
                } catch (error) {
                    console.error("Error al obtener detalles del usuario:", error);
                    return comentario;
                }
            }));
    
            // Ordenar los comentarios del más reciente al más antiguo
            const comentariosOrdenados = comentariosConUsuarios.sort((a, b) => 
                new Date(b.createdDate) - new Date(a.createdDate)
            );

            setComentarios(comentariosOrdenados);
        } catch (error) {
            console.error("Error al cargar comentarios:", error);
            setSnackbarMessage('Error al cargar comentarios');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    useEffect(() => {
        const cargarDatos = async () => {
            try {
                const detalles = await getTasks(jobId);
                setJobDetails(detalles);
                // Obtener detalles del cliente
                const clienteDetalles = await getClienteDetalles(detalles.clientId);
                setClienteDetalles(clienteDetalles); // Almacenar en el estado

                const servicioDetallesResponse = await getClienteService(detalles.clientId);

                if (Array.isArray(servicioDetallesResponse) && servicioDetallesResponse.length > 0) {
                    // Almacenar todos los detalles de servicio en el estado
                    setServicioDetalles(servicioDetallesResponse);
                    
                    // Mostrar todos los detalles de servicio en la consola
                    servicioDetallesResponse.forEach((servicio, index) => {
                    });
                    // Inicializa objetos para almacenar los valores de onuSignal y onuSignalValue por servicio
                    const onuSignalByService = {};
                    const onuSignalValueByService = {};                 

                    servicioDetallesResponse.forEach(async (servicio) => {
                    const gponSerial = getGPONSerialFromServiceDetails(servicio);

    if (gponSerial) {
        const endpoint = `onu/get_onu_signal/${gponSerial}`;
        try {
            const response = await axios.post(
                'https://vps.icentral.net/api/index.php',
                { endpoint: endpoint },
                { headers: { 'Content-Type': 'application/json' } }
            );
            const datosDesdePHP = response.data;

            // Almacena los valores de onuSignal y onuSignalValue por servicio
            onuSignalByService[servicio.id] = datosDesdePHP.onu_signal || '';
            onuSignalValueByService[servicio.id] = datosDesdePHP.onu_signal_value || '';

            // Actualiza el estado con los valores de onuSignal y onuSignalValue
            setOnuSignalByService({ ...onuSignalByService });
            setOnuSignalValueByService({ ...onuSignalValueByService });
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.error('Error 404: Recurso no encontrado.');
            } else if (error.response && error.response.status === 403) {
                console.error('Error 403: Acceso prohibido.');
            } else {
                console.error('Error al obtener datos desde PHP:', error.message);
            }
        }
    } else {
        console.error('GPON Serial not found in service details.');
    }
});}

                cargarComentarios();
 
            } catch (error) {
                console.error("Error al cargar datos del trabajo:", error);
            }
        };

        cargarDatos();

        const fetchTasks = async () => {
            const tasks = await getAllTasks(jobId);
            setTareatask(tasks);
          };
            fetchTasks();
    }, [jobId]);

    // Lógica de paginación
    const indexOfLastComment = currentPage * commentsPerPage;
    const indexOfFirstComment = indexOfLastComment - commentsPerPage;
    const currentComments = comentarios.slice(indexOfFirstComment, indexOfLastComment);

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

       const iniciarServicio = () => {
        updateJobStatus(jobId, 1).then(() => {
            setJobDetails(prevDetails => ({ ...prevDetails, status: 1 }));
        }).catch(error => {
            console.error("Error al iniciar el servicio:", error);
        });
    };
 
    const handleCerrarServicio = () => {
        setOpenConfirmDialog(true);
    };

    const handleConfirmCerrarServicio = () => {
        // Lógica actual para cerrar el servicio
        updateJobStatus(jobId, 2).then(() => {
            setJobDetails(prevDetails => ({ ...prevDetails, status: 2 }));
            setOpenConfirmDialog(false);
            // Aquí puedes añadir lógica adicional después de cerrar el servicio
            setSnackbarMessage('Servicio cerrado correctamente');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        }).catch(error => {
            console.error("Error al cerrar el servicio:", error);
            setOpenConfirmDialog(false);
            setSnackbarMessage('Error al cerrar el servicio');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        });
    };

    const handleCancelCerrarServicio = () => {
        setOpenConfirmDialog(false);
    };

    const handleIrAGoogleMaps = async () => {
        if (servicioDetalles && servicioDetalles.length > 0) {
            const servicio = servicioDetalles[0]; // Suponiendo que deseas usar las coordenadas del primer servicio
    
            if (servicio.addressGpsLat && servicio.addressGpsLon) {
                const enlaceMaps = await crearEnlaceGoogleMaps(servicio.addressGpsLat, servicio.addressGpsLon);
                if (enlaceMaps) window.open(enlaceMaps, "_blank");
            }
        }
    };
    

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const precargarFormulario = async (serviceId) => {
        try {
            const servicioActual = await obtenerServicioCliente(serviceId);
            const nuevoFormulario = {};
            servicioActual.attributes.forEach(attr => {
                const key = Object.keys(attributeMapping).find(k => attributeMapping[k] === attr.customAttributeId);
                if (key) {
                    nuevoFormulario[key] = attr.value;
                }
            });
            setFormulario(nuevoFormulario);
        } catch (error) {
            console.error("Error al precargar el formulario:", error);
        }
    };

    const handleOpenDialog = async () => {
        if (servicioDetalles && servicioDetalles.length > 0) {
            const serviceId = servicioDetalles[0].id;
            await precargarFormulario(serviceId);
        }
        setOpen(true);
    };

    const guardarComentariosYAdjuntos = async () => {
        try {
            // Obtener los valores actuales del servicio
            const serviceId = servicioDetalles[0].id;
            const servicioActual = await obtenerServicioCliente(serviceId);

            // Precargar el formulario con los valores actuales del servicio
            await precargarFormulario(serviceId);

            // Crear un comentario con los valores actuales
            const valoresActuales = servicioActual.attributes
                .map(attr => `${attr.name}: ${attr.value}`)
                .join('\n');
            
            await addComment({
                jobId: jobDetails.id,
                message: `Valores antes de la actualización:\n${valoresActuales}`,
                userId: parseInt(userId, 10)
            });

            // Filtra los campos del formulario que tienen valores
            const camposConValores = Object.entries(formulario)
                .filter(([key, value]) => value)
                .map(([key, value]) => `${key}: ${value}`)
                .join('\n');
    
            // Si no hay campos con valores, no se envía ningún comentario de actualización
            if (camposConValores) {
                await addComment({
                    jobId: jobDetails.id,
                    message: `Valores actualizados:\n${camposConValores}`,
                    userId: parseInt(userId, 10)
                });
            }

            // Prepara los atributos para actualizar el servicio del cliente
            const attributes = Object.entries(formulario)
                .filter(([key, value]) => value) // Solo incluir campos con valor
                .map(([key, value]) => ({
                    customAttributeId: attributeMapping[key],
                    value: String(value)
                }));

            console.log("Attributes a enviar:", JSON.stringify({ attributes }, null, 2));

            // Actualiza el servicio del cliente con los datos del formulario
            const response = await actualizarServicioCliente(serviceId, attributes);
            console.log("Respuesta del servidor:", response);

            // Actualiza el formulario con los valores actuales del servicio
            const servicioActualizado = await obtenerServicioCliente(serviceId);
            setFormulario(prevFormulario => {
                const nuevoFormulario = { ...prevFormulario };
                servicioActualizado.attributes.forEach(attr => {
                    const key = Object.keys(attributeMapping).find(k => attributeMapping[k] === attr.customAttributeId);
                    if (key) {
                        nuevoFormulario[key] = attr.value;
                    }
                });
                return nuevoFormulario;
            });
    
            // Recargar comentarios y adjuntos
            await cargarComentarios();

            handleCloseDialog();
            setSnackbarMessage('Datos guardados correctamente');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Error al guardar comentarios y adjuntos:", error);
            setSnackbarMessage('Error al guardar los datos');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const enviarWhatsapp = (phoneIndex = 0) => {
        if (clienteDetalles && clienteDetalles.contacts && clienteDetalles.contacts.length > phoneIndex) {
          const telefonoCliente = clienteDetalles.contacts[phoneIndex].phone;
      
          // Obtener detalles del trabajo
          const { firstName, lastName, fullAddress } = clienteDetalles;
          const { id } = jobDetails;
      
          // Construir el mensaje personalizado
          const mensaje = `Hola, ${firstName} ${lastName}, me comunico de *iCentral* para atender la orden ${id} con *Domicilio:* ${fullAddress}. *¿Me puede recibir?*`;
      
          // Codificar el mensaje para agregarlo a la URL de WhatsApp
          const mensajeCodificado = encodeURIComponent(mensaje);
      
          // Construir la URL de WhatsApp
          const urlWhatsapp = `https://wa.me/${telefonoCliente}?text=${mensajeCodificado}`;
      
          // Abrir la ventana de WhatsApp con el mensaje predefinido
          window.open(urlWhatsapp, '_blank');
        }
      };
    
      const llamarDirecto = (phoneIndex = 0) => {
        if (clienteDetalles && clienteDetalles.contacts && clienteDetalles.contacts.length > phoneIndex) {
          const telefonoCliente = clienteDetalles.contacts[phoneIndex].phone;
          const urlLlamadaDirecta = `tel:${telefonoCliente}`;
          window.open(urlLlamadaDirecta, '_blank');
        }
      };

      const interpretarEstadoServicio = (estadoNum) => {
        const estadoMapping = {
          1: 'Activo',
          2: 'Cancelado',
          3: 'Suspendido',
          7: 'Instalación',
          8: 'Inactivo'
        };
      
        // Devuelve la descripción correspondiente o 'Desconocido' si no hay coincidencia
        return estadoMapping[estadoNum] || 'Desconocido';
      };
     
      if (!tareatask) {
        return <div>Cargando...</div>; // Muestra un mensaje de carga mientras se obtienen las tareas
      }

    const handleOpenNotesDialog = () => {
        setOpenNotesDialog(true);
    };

    const handleCloseNotesDialog = () => {
        setOpenNotesDialog(false);
        setAdditionalNote('');
    };

    const handleAdditionalNoteChange = (event) => {
        setAdditionalNote(event.target.value);
    };

    const handleSubmitAdditionalNote = async () => {
        if (!additionalNote.trim()) {
            setSnackbarMessage('La nota no puede estar vacía');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        try {
            const commentData = {
                jobId: parseInt(jobId, 10),
                message: additionalNote,
                userId: parseInt(userId, 10)
            };

            await addComment(commentData);
            setSnackbarMessage('Nota adicional enviada correctamente');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            handleCloseNotesDialog();
            await cargarComentarios();
        } catch (error) {
            console.error("Error al enviar la nota adicional:", error);
            setSnackbarMessage('Error al enviar la nota adicional');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    return (
        <Layout>
            <Container>
                <Box mt={3}>
                <Button 
                        startIcon={<ArrowBackIcon />} 
                        onClick={handleBackToTasks} 
                        variant="contained" 
                        color="primary"
                        style={{ marginBottom: '20px' }}
                    >
                        Volver a Tareas
                    </Button>
                    <Typography variant="h4" gutterBottom>
                        Detalles del Trabajo
                    </Typography>
                    {jobDetails ? (
                        <Card>
                            <CardContent>
                                <Typography variant="h5">{jobDetails.title} </Typography>
                                
                                <Typography variant="body1">{jobDetails.description}</Typography>
                                <Typography color="textSecondary">ID: {jobDetails.id}</Typography>
                                <Typography variant="body2">Fecha: {new Date(jobDetails.date).toLocaleString()}</Typography>
                                <Typography variant="body2">Duración: {jobDetails.duration} minutos</Typography>
                                <Typography variant="body2">Estado: {jobDetails.status === 0 ? 'Pendiente' : jobDetails.status === 1 ? 'En Proceso' : 'Completado'}</Typography>
                                <Typography variant="body2">
                                    {tareatask && tareatask.length > 0 && tareatask.map((task) => (
                                        <span key={task.id}>
                                            Cobro: ${task.label}
                                            <br />
                                        </span>
                                    ))}
                                </Typography>

                                <ServiceDetails
                                    servicioDetalles={servicioDetalles}
                                    clienteDetalles={clienteDetalles}
                                    jobDetails={jobDetails}
                                    interpretarEstadoServicio={interpretarEstadoServicio}
                                    filtrarAtributos={filtrarAtributos}
                                    onuSignalByService={onuSignalByService}
                                    onuSignalValueByService={onuSignalValueByService}
                                    handleIrAGoogleMaps={handleIrAGoogleMaps}
                                    llamarDirecto={llamarDirecto}
                                    enviarWhatsapp={enviarWhatsapp}
                                />
                            </CardContent>
                        </Card>
                    ) : <Typography>Cargando...</Typography>}
                      


                </Box>
               
                {jobDetails && jobDetails.status === 0 && (
                    <>
                        <Button 
                            onClick={handleOpenStartServiceDialog} 
                            variant="contained" 
                            color="primary"
                            style={{ backgroundColor: '#4caf50', color: '#fff', fontWeight: 'bold' }}
                        >
                            Iniciar Servicio
                        </Button>
                        <StartServiceDialog
                            open={openStartServiceDialog}
                            onClose={handleCloseStartServiceDialog}
                            onConfirm={handleConfirmStartService}
                        />
                    </>
                )}

                 {jobDetails && jobDetails.status === 1 && (
                    <DialogsAndButtons
                        open={open}
                        setOpen={setOpen}
                        openNotesDialog={openNotesDialog}
                        handleOpenNotesDialog={handleOpenNotesDialog}
                        handleCloseNotesDialog={handleCloseNotesDialog}
                        handleSubmitAdditionalNote={handleSubmitAdditionalNote}
                        handleCloseDialog={handleCloseDialog}
                        handleCerrarServicio={handleCerrarServicio}
                        guardarComentariosYAdjuntos={guardarComentariosYAdjuntos}
                        handleInputChange={handleInputChange}
                        handleGponSerialConfirmation={handleGponSerialConfirmation}
                        toggleGponSerialConfirmationVisibility={toggleGponSerialConfirmationVisibility}
                        gponSerialError={gponSerialError}
                        formulario={formulario}
                        gponSerialConfirmation={gponSerialConfirmation}
                        showGponSerialConfirmation={showGponSerialConfirmation}
                        additionalNote={additionalNote}
                        handleAdditionalNoteChange={handleAdditionalNoteChange}
                        handleOpenDialog={handleOpenDialog} // Añadido para abrir el diálogo y precargar el formulario
                        serviceId={obtenerServiceIds()[0]} 
                        />
                )}
                
                <Divider style={{ margin: '20px 0' }} />
                <div>
    <Typography variant="h6">Comentarios:</Typography>
    {comentarios.map(comentario => (
        <Paper key={comentario.id} elevation={3} style={{ padding: 15, margin: '10px 0' }}>
            <Typography variant="body1">Comentario: {comentario.message}</Typography>
            <Typography variant="body2">Usuario: {comentario.usuarioNombre}</Typography>
            <Typography variant="body2">
                <strong>Fecha de Creación:</strong> {format(new Date(comentario.createdDate), 'dd/MM/yyyy HH:mm')}
            </Typography>
        </Paper>
    ))}
</div>

            </Container>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <ConfirmCloseServiceDialog
            open={openConfirmDialog}
            onClose={handleCancelCerrarServicio}
            onConfirm={handleConfirmCerrarServicio}
        />        
        </Layout>
    );
};

export default JobDetails;
