import React from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SignalQualityAlert = ({ signal, signalValue, servicioId }) => {
  const getAlertProps = () => {
    switch (signal) {
      case 'Very good':
        return {
          severity: 'success',
          icon: <CheckCircleIcon fontSize="inherit" />,
          title: '¡Muy bien!',
        };
      case 'Warning':
        return {
          severity: 'warning',
          icon: <WarningIcon fontSize="inherit" />,
          title: 'Advertencia',
        };
      case 'Critical':
        return {
          severity: 'error',
          icon: <ErrorIcon fontSize="inherit" />,
          title: '¡Crítico!',
        };
      default:
        return {
          severity: 'info',
          icon: null,
          title: 'No Hay Datos Para mostrar',
        };
    }
  };

  const alertProps = getAlertProps();

  return (
    <Alert severity={alertProps.severity} variant="filled" sx={{ textAlign: 'center' }}>
      {alertProps.icon && <AlertTitle>Calidad de señal: <strong>{alertProps.title}</strong></AlertTitle>}
      {signalValue && (
        <Typography variant="body2">
          Señal para el servicio {servicioId}: {signalValue}
        </Typography>
      )}
    </Alert>
  );
};

export default SignalQualityAlert;
