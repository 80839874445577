import React, { useState, useEffect,useRef } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";  
import SignaturePad from 'signature_pad';

import { getDatabase, ref as dbRef, push } from 'firebase/database';
import { firebaseApp } from '../firebase/firebaseConfig'; 
import { Grid, TextField, Typography, Box, Button,  } from '@mui/material';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { set } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const database = getDatabase(firebaseApp);
const OrdenDeServicio = ({ jobDetails, servicioDetalles, } ) => {
    const { id, title, clientId, date } = jobDetails || {};
    const { status,  fullAddress,firstName, lastName,attributes, }= servicioDetalles || {};
    const [uploads, setUploads] = useState([]); 
  const [detallesCorreccion, setDetallesCorreccion] = useState('');
  const [potenciaDbm, setPotenciaDbm] = useState('');
  const [cantidadRecibida, setCantidadRecibida] = useState('');
  const [imagenFallaURL, setImagenFallaURL] = useState('');
  const [equiposInstalados, setEquiposInstalados] = useState('');
  const [serieEquipo, setSerieEquipo] = useState('');
  const [modelo, setModelo] = useState(''); 
  const [marca, setMarca] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0); 
  const [enviado, setEnviado] = useState(false); // Estado para el mensaje de éxito
  const [tipoServicio, setTipoServicio] = useState(''); 
    const [visitaenfalso, setVisitaenfalso] = useState('');
    const [firmaTecnico, setFirmaTecnico] = useState(null); // Estado para almacenar la firma del técnico
    const [firmaCliente, setFirmaCliente] = useState(null); // Estado para almacenar la firma del cliente
    const signaturePadRefTecnico = useRef(null);
    const signaturePadRefCliente = useRef(null);
    const [openTecnico, setOpenTecnico] = useState(false);
    const [openCliente, setOpenCliente] = useState(false);
    const signaturePadTecnico = useRef(null);
  const signaturePadCliente = useRef(null);
 
 

  
  const handleOpenTecnico = () => {
    setOpenTecnico(true);
    setTimeout(() => {
      const canvasTecnico = signaturePadRefTecnico.current;
      if (canvasTecnico) {
        // Establecer el ancho y el alto del canvas a las dimensiones de la ventana
        canvasTecnico.width = window.innerWidth;
        canvasTecnico.height = window.innerHeight;
        signaturePadTecnico.current = new SignaturePad(canvasTecnico, {
          backgroundColor: 'rgb(255, 255, 255)'
        });
  
        // Escuchar el evento de cambio de orientación
        window.addEventListener('orientationchange', () => {
          // Ajustar las dimensiones del canvas a las nuevas dimensiones de la ventana
          canvasTecnico.width = window.innerWidth;
          canvasTecnico.height = window.innerHeight;
          // Limpiar y reiniciar SignaturePad para que se ajuste a las nuevas dimensiones del canvas
          signaturePadTecnico.current.clear();
          signaturePadTecnico.current = new SignaturePad(canvasTecnico, {
            backgroundColor: 'rgb(255, 255, 255)'
          });
        });
      }
    }, 0);
  };
  
      const handleCloseTecnico = () => {
        setOpenTecnico(false);
      };
    

      const handleOpenCliente = () => {
        setOpenCliente(true);
        setTimeout(() => {
          const canvasCliente = signaturePadRefCliente.current;
          if (canvasCliente) {
            // Establecer el ancho y el alto del canvas a las dimensiones de la ventana
            canvasCliente.width = window.innerWidth;
            canvasCliente.height = window.innerHeight;
            signaturePadCliente.current = new SignaturePad(canvasCliente, {
              backgroundColor: 'rgb(255, 255, 255)'
            });
      
            // Escuchar el evento de cambio de orientación
            window.addEventListener('orientationchange', () => {
              // Ajustar las dimensiones del canvas a las nuevas dimensiones de la ventana
              canvasCliente.width = window.innerWidth;
              canvasCliente.height = window.innerHeight;
              // Limpiar y reiniciar SignaturePad para que se ajuste a las nuevas dimensiones del canvas
              signaturePadCliente.current.clear();
              signaturePadCliente.current = new SignaturePad(canvasCliente, {
                backgroundColor: 'rgb(255, 255, 255)'
              });
            });
          }
        }, 0);
      };
    
      const handleCloseCliente = () => {
        setOpenCliente(false);
      };

      
  // Manejo del cambio en el Select 'tipoServicio'
  const handleChangeControlador = (event) => {
    setTipoServicio(event.target.value);
  };

   // Manejo de carga de imágenes  
   const handleFileUpload = async (event) => {
    const files = event.target.files || []; 
  
    // Inicializar un array vacío para almacenar los archivos de las firmas
    const firmaFiles = [];
  
    // Comprobar si la firma del técnico está presente
    if (signaturePadTecnico && signaturePadTecnico.current) {
      // Convertir la firma del técnico en un blob
      const firmaTecnicoData = signaturePadTecnico.current.toDataURL();
      const firmaTecnicoBlob = await (await fetch(firmaTecnicoData)).blob();
      // Crear un archivo de la firma del técnico
      const firmaTecnicoFile = new File([firmaTecnicoBlob], `${id}_firmaTecnico.png`, { type: 'image/png' });
      // Añadir el archivo de la firma del técnico al array de archivos de las firmas
      firmaFiles.push(firmaTecnicoFile);
    }
  
    // Comprobar si la firma del cliente está presente
    if (signaturePadCliente && signaturePadCliente.current) {
      // Convertir la firma del cliente en un blob
      const firmaClienteData = signaturePadCliente.current.toDataURL();
      const firmaClienteBlob = await (await fetch(firmaClienteData)).blob();
      // Crear un archivo de la firma del cliente
      const firmaClienteFile = new File([firmaClienteBlob], `${id}_firmaCliente.png`, { type: 'image/png' });
      // Añadir el archivo de la firma del cliente al array de archivos de las firmas
      firmaFiles.push(firmaClienteFile);
    }
  
    // Añadir las firmas a los archivos
    const allFiles = [...files, ...firmaFiles];
  
    for (const file of allFiles) {
      const storage = getStorage();
      const storageRef = ref(storage, `imagenes//${id}/${file.name}`);
  
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on('state_changed',
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress); 
        },
        (error) => {
            console.error("Error:", error)
        },
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setImagenFallaURL(downloadURL); 
            });
        }
      );
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

  
    push(dbRef(database, 'ordenes'), {
      detallesCorreccion,
      potenciaDbm,
      cantidadRecibida,
      imagenFallaURL,
      equiposInstalados,
      visitaenfalso,
      serieEquipo,
      modelo,
      marca,
      id,
      title,
      clientId,
      date,
      firstName,
        fullAddress,
        firmaTecnico,
      firmaCliente,
    })
    .then(() => {
      setEnviado(true);
      // Reiniciar el formulario. Puedes adaptarlo si prefieres reiniciar solo ciertos campos:
      setDetallesCorreccion('');
      setPotenciaDbm('');
      setCantidadRecibida('');
      setImagenFallaURL('');
      setEquiposInstalados('');
      setSerieEquipo('');
      setVisitaenfalso('');
      setModelo('');
      setMarca('');
      setTipoServicio('');
      setUploadProgress(0);
    })
    .catch((error) => {
      console.error('Error al enviar los datos:', error);
    }); 
  };


  const resetSignature = () => {
    if (signaturePadTecnico.current) {
      signaturePadTecnico.current.clear();
    }
    if (signaturePadCliente.current) {
      signaturePadCliente.current.clear();
    }
  };

  useEffect(() => {
    const canvasTecnico = signaturePadRefTecnico.current; 
    const canvasCliente = signaturePadRefCliente.current; 

    if (canvasTecnico && canvasCliente) { 
      signaturePadTecnico.current = new SignaturePad(canvasTecnico, {
        backgroundColor: 'rgb(255, 255, 255)'
      });
      signaturePadCliente.current = new SignaturePad(canvasCliente, {
        backgroundColor: 'rgb(255, 255, 255)'
      });

      // Limpiar la firma al enviar el formulario
      if (enviado) {
        resetSignature();
      }
    }

    // Limpiar eventos al desmontar el componente
    return () => {
      if (signaturePadTecnico.current) {
        signaturePadTecnico.current.off();
      }
      if (signaturePadCliente.current) {
        signaturePadCliente.current.off();
      }
    };
  }, [enviado]);
  
    
  return (
    <form onSubmit={handleSubmit}>
         {enviado && <Alert severity="success">Datos enviados correctamente</Alert>}  
       

    <Grid container spacing={1} >

    <Grid  item >
  <FormControl >
<InputLabel htmlFor="detallesCorreccion">Detalles de la corrección</InputLabel>
    <OutlinedInput id="detallesCorreccion" label="Detalles de la corrección" value={detallesCorreccion} onChange={(e) => setDetallesCorreccion(e.target.value)} />
  </FormControl>
</Grid>
<Grid  item >
  <FormControl >
<InputLabel htmlFor="potenciaDbm">Potencia en dBm</InputLabel>
    <OutlinedInput id="potenciaDbm" label="Potencia en dBm" value={potenciaDbm} onChange={(e) => setPotenciaDbm(e.target.value)} />
    </FormControl>

</Grid>
<Grid  item >
  <Typography variant="h6">Imagenes:</Typography>
      <input type="file" accept="image/*" multiple onChange={handleFileUpload} />
      {uploadProgress > 0 && <p>Progreso de carga: {uploadProgress}%</p>}

      <Grid  item >
          <Button variant="contained" color="primary" onClick={handleOpenTecnico}>
            Firma del técnico
          </Button>
          <Dialog open={openTecnico} onClose={handleCloseTecnico} fullScreen>
            <DialogTitle>Firma del técnico</DialogTitle>
            <DialogContent>
              <canvas id="signature-pad-tecnico" ref={signaturePadRefTecnico}></canvas> 
            </DialogContent>
            <DialogActions>
              <Button onClick={resetSignature}>Limpiar</Button>
              <Button onClick={handleCloseTecnico}>Guardar</Button>
            </DialogActions>
          </Dialog>
        </Grid>

        <Grid  item >
          <Button variant="contained" color="primary" onClick={handleOpenCliente}>
            Firma del cliente
          </Button>
          <Dialog open={openCliente} onClose={handleCloseCliente} fullScreen>
            <DialogTitle>Firma del cliente</DialogTitle>
            <DialogContent>
              <canvas id="signature-pad-cliente" ref={signaturePadRefCliente}></canvas> 
            </DialogContent>
            <DialogActions>
              <Button onClick={resetSignature}>Limpiar</Button>
              <Button onClick={handleCloseCliente}>Guardar</Button>
            </DialogActions>
          </Dialog>
        </Grid>

  </Grid>
  </Grid>
  <Grid container >
  <Grid item xs>
    <div>

      <Select value={tipoServicio} label="Selecciona un tipo de Servicio" onChange={handleChangeControlador}>
        <MenuItem value="instalacion">Instalación</MenuItem>
        <MenuItem value="reemplazo">Reemplazo</MenuItem>
        <MenuItem value="reubicado">Reubicado</MenuItem>
        <MenuItem value="removido">Removido</MenuItem>
        <MenuItem value="visitafalso">Visita en falso</MenuItem>

      </Select>
      {tipoServicio && ( // Mostrar solo si se ha seleccionado un controlador 
        <div>
            {tipoServicio === "instalacion" && ( 
            <div>
          <FormControl>
            <InputLabel htmlFor="serieEquipo">Serie de equipo</InputLabel>
            <OutlinedInput id="serieEquipo" label="Serie de equipo" value={serieEquipo} onChange={(e) => setSerieEquipo(e.target.value)} />
            </FormControl>
          
          <Typography variant="h6">Modelo:</Typography>
          <TextField value={modelo} onChange={(e) => setModelo(e.target.value)} />

          <Typography variant="h6">Marca:</Typography>
          <TextField value={marca} onChange={(e) => setMarca(e.target.value)} />
          <FormControl>
<InputLabel htmlFor="cantidadRecibida">Cantidad Recibida</InputLabel>
    <OutlinedInput id="cantidadRecibida" label="Cantidad Recibida" value={cantidadRecibida} onChange={(e) => setCantidadRecibida(e.target.value)} />
    </FormControl>

          </div>
          )}
          {tipoServicio === "reemplazo" && (
            
            <div>
                
                 <Typography variant="h6">Equipos Nuevos:</Typography>
                <TextField value={equiposInstalados} onChange={(e) => setEquiposInstalados(e.target.value)} />
                <Typography variant="h6">Serie de equipo:</Typography>
                <TextField value={serieEquipo} onChange={(e) => setSerieEquipo(e.target.value)} />
               
          
         </div>
      )}

        {tipoServicio === "reubicado" && (
                
                <div>
                    
                    <Typography variant="h6">Equipos instalados:</Typography>
                    <TextField value={equiposInstalados} onChange={(e) => setEquiposInstalados(e.target.value)} />
                    <Typography variant="h6">Serie de equipo:</Typography>
                    <TextField value={serieEquipo} onChange={(e) => setSerieEquipo(e.target.value)} />
                    <Typography variant="h6">Modelo:</Typography>
                    <TextField value={modelo} onChange={(e) => setModelo(e.target.value)} />
                    <Typography variant="h6">Marca:</Typography>
                    <TextField value={marca} onChange={(e) => setMarca(e.target.value)} />
    
            
            </div>
        )}
        {tipoServicio === "removido" && (
                
                <div>
                    
                    <Typography variant="h6">Equipos Desinstalados:</Typography>
                    <TextField value={equiposInstalados} onChange={(e) => setEquiposInstalados(e.target.value)} />
                    <Typography variant="h6">Serie de equipo:</Typography>
                    <TextField value={serieEquipo} onChange={(e) => setSerieEquipo(e.target.value)} />
                    <Typography variant="h6">Modelo:</Typography>
                    <TextField value={modelo} onChange={(e) => setModelo(e.target.value)} />
                    <Typography variant="h6">Marca:</Typography>
                    <TextField value={marca} onChange={(e) => setMarca(e.target.value)} />
    
            
            </div>
        )}
        {tipoServicio === "visitafalso" && (
                
                <div>
                    
                    <FormControl>
                        <InputLabel id="visitaenfalso">Porque no recibierion</InputLabel>
                        <OutlinedInput id="visitaenfalso" label="Porque no recibierion" value={visitaenfalso} onChange={(e) => setEquiposInstalados(e.target.value)} />
</FormControl>

    </div> 

        )}
    </div>
      )}
    </div>

    </Grid>
        <Box mt={2}>
             <Button variant="contained" color="primary" type="submit">Enviar</Button>
        </Box>
 </Grid>
         
         
     </form>
  );
};

export default OrdenDeServicio;