import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, InputAdornment, IconButton, TextField, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { obtenerDatosDelServicio } from '../components/apiService';
import Alert from '@mui/material/Alert';

const DialogsAndButtons = ({
    open,
    setOpen,
    openNotesDialog,
    handleOpenNotesDialog,
    handleCloseNotesDialog,
    handleSubmitAdditionalNote,
    handleCloseDialog,
    handleCerrarServicio,
    guardarComentariosYAdjuntos,
    handleInputChange,
    handleGponSerialConfirmation,
    toggleGponSerialConfirmationVisibility,
    gponSerialError,
    formulario,
    gponSerialConfirmation,
    showGponSerialConfirmation,
    additionalNote,
    handleAdditionalNoteChange,
    handleOpenDialog, // Añadido para abrir el diálogo y precargar el formulario
    serviceId // Añadido para recibir el serviceId
}) => {
    useEffect(() => {
        if (open && serviceId) {
            handleOpenDialog(serviceId);
        }
    }, [open, serviceId]);

    const [alertMessage, setAlertMessage] = useState('');
    const [openJustificationDialog, setOpenJustificationDialog] = useState(false);
    const [justification, setJustification] = useState('');
    const [missingFields, setMissingFields] = useState([]);

    const handleFinalizarServicio = async () => {
        const { attributeData } = await obtenerDatosDelServicio(serviceId);
        const { gponSerial, splitterPort, nap, candado } = attributeData;

        const fields = [];
        if (!gponSerial) fields.push({ field: 'gponSerial', label: 'GPON Serial' });
        if (!splitterPort) fields.push({ field: 'splitterPort', label: 'Splitter Port' });
        if (!nap) fields.push({ field: 'nap', label: 'NAP' });
        if (!candado) fields.push({ field: 'candado', label: 'Candado' });

        if (fields.length > 0) {
            setMissingFields(fields);
            setOpenJustificationDialog(true);
        } else {
            handleCerrarServicio();
        }
    };

    const handleJustificationChange = (e) => {
        setJustification(e.target.value);
    };

    const handleSubmitJustification = () => {
        const allFieldsFilled = missingFields.every(({ field }) => formulario[field]);

        if (allFieldsFilled || (justification.trim() && justification.trim().length >= 20)) {
            missingFields.forEach(({ field }) => {
                if (!formulario[field]) {
                    handleInputChange(field, justification);
                }
            });

            if (justification.trim()) {
                handleAdditionalNoteChange({
                    target: { value: justification }
                });
                handleSubmitAdditionalNote({
                    target: { value: justification }
                });
            }

            // Llamar a las funciones relacionadas para procesar y enviar los datos
            guardarComentariosYAdjuntos();

            setJustification('');
            setOpenJustificationDialog(false);
            handleCerrarServicio();
        } else {
            setAlertMessage('Debe llenar todos los campos faltantes o proporcionar una justificación de al menos 20 caracteres.');
        }
    };

    return (
        <>
            <Divider style={{ margin: '20px 0' }} />
            <Box display="flex" justifyContent="flex-start" mt={2}>
                <Button onClick={() => handleOpenDialog(serviceId)} variant="contained" color="primary">
                    Capturar Datos
                </Button>
                <Box ml={1.25}> {/* Esto añade un margen izquierdo de 10px (1.25 * 8px) */}
                    <Button 
                        onClick={handleOpenNotesDialog} 
                        variant="contained" 
                        color="primary"
                    >
                        Agregar Nota Adicional
                    </Button>
                </Box>
            </Box>
            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Capturar Datos</DialogTitle>
                <DialogContent>
                    <Box mb={1} mt={1}>  
                        <TextField
                            label="GPON Serial"
                            value={formulario.gponSerial || ''}
                            onChange={(e) => handleInputChange('gponSerial', e.target.value)}
                            fullWidth
                        />
                    </Box>
                    <Box mb={1}>
                        <TextField
                            label="Confirmar GPON Serial"
                            value={gponSerialConfirmation || ''}
                            onChange={(e) => handleGponSerialConfirmation(e.target.value)}
                            fullWidth
                            type={showGponSerialConfirmation ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle gpon serial confirmation visibility"
                                            onClick={toggleGponSerialConfirmationVisibility}
                                            edge="end"
                                        >
                                            {showGponSerialConfirmation ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box mb={1}>
                        <TextField
                            label="Secondary Equipment Model"
                            value={formulario.secondaryEquipmentModel || ''}
                            onChange={(e) => handleInputChange('secondaryEquipmentModel', e.target.value)}
                            fullWidth
                        />
                    </Box>
                    <Box mb={1}>
                        <TextField
                            label="Splitter Port"
                            value={formulario.splitterPort || ''}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value === '' || /^[0-9]+$/.test(value)) {
                                    handleInputChange('splitterPort', value);
                                }
                            }}
                            onBlur={(e) => {
                                const value = e.target.value;
                                if (value !== '' && !isNaN(value)) {
                                    handleInputChange('splitterPort', Math.floor(Number(value)).toString());
                                }
                            }}
                            fullWidth
                            type="number"
                            inputProps={{ min: 0, step: 1 }}
                            helperText="Ingrese un número"
                        />
                    </Box>
                    <Box mb={1}>
                        <TextField
                            label="NAP"
                            value={formulario.nap || ''}
                            onChange={(e) => handleInputChange('nap', e.target.value)}
                            fullWidth
                        />
                    </Box>
                    <Box mb={2}>  {/* Margen inferior más grande antes del botón */}
                        <TextField
                            label="Candado"
                            value={formulario.candado || ''}
                            onChange={(e) => handleInputChange('candado', e.target.value)}
                            fullWidth
                        />
                    </Box>
                    <Button 
                        onClick={guardarComentariosYAdjuntos} 
                        variant="contained" 
                        color="primary"
                        fullWidth
                    >
                        Enviar Datos
                    </Button>
                </DialogContent>
            </Dialog>
            <Dialog open={openNotesDialog} onClose={handleCloseNotesDialog}>
                <DialogTitle>Agregar Nota Adicional</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="note"
                        label="Nota Adicional"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={additionalNote}
                        onChange={handleAdditionalNoteChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNotesDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmitAdditionalNote} color="primary">
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openJustificationDialog} onClose={() => setOpenJustificationDialog(false)}>
                <DialogTitle>Justificación</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        Por favor, complete los campos faltantes o proporcione una justificación detallada de por qué no se pueden llenar los datos faltantes.
                    </Typography>
                    {missingFields.map(({ field, label }) => (
                        <Box mb={1} key={field}>
                            <TextField
                                label={label}
                                value={formulario[field] || ''}
                                onChange={(e) => handleInputChange(field, e.target.value)}
                                fullWidth
                            />
                        </Box>
                    ))}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="justification"
                        label="Justificación"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={justification}
                        onChange={(e) => {
                            handleJustificationChange(e);
                            handleAdditionalNoteChange(e);
                        }}
                        error={!justification.trim() && !missingFields.every(({ field }) => formulario[field])}
                        helperText={!justification.trim() && !missingFields.every(({ field }) => formulario[field]) ? 'Debe llenar todos los campos faltantes o proporcionar una justificación con detalles.' : ''}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        console.log("Cancelar justificación");
                        setOpenJustificationDialog(false);
                    }} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmitJustification} color="primary">
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
            <Button onClick={handleFinalizarServicio} variant="contained" color="secondary" style={{ marginTop: 15, marginLeft: 15 }}>Finalizar Servicio</Button>
            {alertMessage && (
                <Alert severity="error" onClose={() => setAlertMessage('')}>
                    {alertMessage}
                </Alert>
            )}
        </>
    );
};

export default DialogsAndButtons;