import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Container,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { addJob, fetchAllUsers } from './apiService';
import Layout from './Layout';


const NuevoTrabajoForm = () => {
  const [pin, setPin] = useState('');
  const [showForm, setShowForm] = useState(false);
  const correctPin = '202024';
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [assignedUserId, setAssignedUserId] = useState('');
  const [clientId, setClientId] = useState(1);
  const [date, setDate] = useState(new Date());
  const [duration, setDuration] = useState('');
  const [status, setStatus] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');


  const handleSubmitPin = (e) => {
    e.preventDefault();

    if (pin === correctPin) {
      setShowForm(true);
    } else {
      console.log('PIN incorrecto');
      // Puedes mostrar un mensaje de error o redirigir
    }
  };
  
  useEffect(() => {
    
    const cargarUsuarios = async () => {
      try {
        const usuarios = await fetchAllUsers();
        setAllUsers(usuarios);
      } catch (error) {
        console.error('Error al cargar usuarios:', error);
      }
    };

    cargarUsuarios();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nuevoTrabajo = {
      title: titulo,
      description: descripcion,
      assignedUserId: assignedUserId,
      clientId: parseInt(clientId, 10), // Convertir a entero
      date: dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ'),
      duration: parseInt(duration, 10),
      status: status,
    };

    try {
      const respuesta = await addJob(nuevoTrabajo);
      console.log('Trabajo creado con éxito:', respuesta);
      setSuccessMessage('Trabajo creado con éxito');
      // Resetear los campos del formulario
      setTitulo('');
      setDescripcion('');
      setAssignedUserId('');
      setClientId(1);
      setDate(new Date());
      setDuration('');
      setStatus(0);
    } catch (error) {
      console.error('Error al crear el trabajo:', error);
      setSuccessMessage('');
    }
  };

  return (
    <Layout>
     <Container fixed>
        {!showForm ? (
          <form onSubmit={handleSubmitPin}>
            <TextField
              label="PIN"
              fullWidth
              type="password"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              required
            />
            <Button type="submit" variant="contained" color="primary">
              Acceder
            </Button>
          </form>
        ) : (
          <Grid container
            direction="column"
            justifyContent="space-around"
            alignItems="baseline">

    <form onSubmit={handleSubmit}>
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      
      <Grid item xs={12}>
        <TextField
          label="Título"
          fullWidth
          value={titulo}
          onChange={(e) => setTitulo(e.target.value)}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Descripción"
          fullWidth
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Asignar Usuario</InputLabel>
          <Select
            value={assignedUserId}
            onChange={(e) => setAssignedUserId(e.target.value)}
            required
          >
            {allUsers.map((usuario) => (
              <MenuItem key={usuario.id} value={usuario.id}>
                {usuario.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <DatePicker
          selected={date}
          onChange={(newDate) => setDate(newDate)}
          showTimeSelect
          dateFormat="Pp"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Duración (minutos)"
          type="number"
          fullWidth
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Cliente ID"
          type="number"
          fullWidth
          value={clientId}
          onChange={(e) => setClientId(e.target.value)}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <Button type="submit" variant="contained" color="primary">
          Crear Trabajo
        </Button>
      </Grid>
    </form>
  </Grid>
   )}
</Container>


    </Layout>
  );
};

export default NuevoTrabajoForm;
