import React from 'react';
import { Typography, Grid, Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const ContactOptions = ({ clienteDetalles, llamarDirecto, enviarWhatsapp }) => {
    return (
        <Typography variant='subtitle1'>
            {clienteDetalles.contacts && clienteDetalles.contacts.length > 0 ? (
                <div>
                    <Typography variant="subtitle1">
                        {clienteDetalles.contacts.length > 1 ? 'Contactos:' : 'Contacto:'}
                    </Typography>
                    <Grid container spacing={2} mb={1}>
                        {clienteDetalles.contacts.map((contacto, index) => (
                            <Grid item xs={12} key={index}>
                                <Typography variant="subtitle1">
                                    Opción {index + 1}:
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="info"
                                    startIcon={<PhoneIcon />}
                                    onClick={() => llamarDirecto(index)} // Usar la función llamarDirecto
                                    style={{ marginLeft: 10 }}
                                >
                                    Llamar
                                </Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    startIcon={<WhatsAppIcon />}
                                    onClick={() => enviarWhatsapp(index)} // Usar la función enviarWhatsapp
                                    style={{ marginLeft: 10 }}
                                >
                                    WhatsApp
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            ) : (
                <span> No hay números adicionales </span>
            )}
        </Typography>
    );
};

export default ContactOptions;