import React from 'react';
import { Typography, Card, CardContent, Grid, Button } from '@mui/material';
import DirectionsIcon from '@mui/icons-material/Directions';
import SignalQualityAlert from '../components/SignalQualityAlert';
import ContactOptions from './ContactOptions';

const ServiceDetails = ({
    servicioDetalles,
    clienteDetalles,
    jobDetails,
    interpretarEstadoServicio,
    filtrarAtributos,
    onuSignalByService,
    onuSignalValueByService,
    handleIrAGoogleMaps,
    llamarDirecto,
    enviarWhatsapp
}) => {
    return (
        <Grid>
            {servicioDetalles && servicioDetalles.map((servicio, index) => (
                <Grid item key={index} style={{ gridArea: '3' }}>
                    <Card sx={{ border: '8px solid #ddd', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                        <CardContent>
                            <Typography variant="h6">Detalles del Servicio {servicio.id}:</Typography>
                            <Typography variant="subtitle1">Dirección: {clienteDetalles.fullAddress}</Typography>
                            <Typography variant="subtitle1">
                                Nombre del TT: {clienteDetalles.clientType === 2
                                    ? `${clienteDetalles.companyName} - Contacto: ${clienteDetalles.companyContactFirstName} ${clienteDetalles.companyContactLastName}`
                                    : `${clienteDetalles.firstName} ${clienteDetalles.lastName}`}
                            </Typography>
                            <Typography variant="subtitle1">Numero de cliente: CJ{servicio.clientId}</Typography>
                            <Typography variant="subtitle1">Nombre del Servicio: {servicio.name}</Typography>
                            <Typography variant="subtitle1">Estado de Servicio: {interpretarEstadoServicio(servicio.status)}</Typography>
                            <Typography variant="subtitle1">Atributos del Servicio:</Typography>
                            <ul>
                                {filtrarAtributos(servicio.attributes).map((atributo, index) => (
                                    <li key={index}>
                                        {atributo.name}: {atributo.value}
                                    </li>
                                ))}
                            </ul>
                            {jobDetails.status === 1 && (
                                <ContactOptions 
                                    clienteDetalles={clienteDetalles} 
                                    llamarDirecto={llamarDirecto} 
                                    enviarWhatsapp={enviarWhatsapp} 
                                />
                            )}
                            <div>
                                <SignalQualityAlert
                                    signal={onuSignalByService[servicio.id]}
                                    signalValue={onuSignalValueByService[servicio.id]}
                                    servicioId={servicio.id}
                                />
                            </div>
                            {jobDetails.status === 1 && (
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    startIcon={<DirectionsIcon />}
                                    onClick={handleIrAGoogleMaps}
                                    style={{ marginTop: 15, width: '100%' }}
                                >
                                    Ir a Google Maps
                                </Button>
                            )}
                           
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default ServiceDetails;