// apiService.js
import httpClient from './httpClient';

const convertirArchivoABase64 = (archivo) => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(archivo);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
  });
};

export const subirAdjunto = async (jobId, archivo, nombreArchivo) => {
  const archivoBase64 = await convertirArchivoABase64(archivo);
  const datosAdjunto = {
      jobId: jobId,
      filename: nombreArchivo,
      file: archivoBase64.split(',')[1] // Elimina la parte del prefijo data URL
  };

  return httpClient(`scheduling/jobs/attachments`, {
      method: 'POST',
      body: datosAdjunto
  });
};

export const fetchAllUsers = () => {
    
    return httpClient('users/admins')
        .then(response => {
           
            return response;
        })
        .catch(error => {
            console.error("Error al obtener usuarios:", error);
            throw error;
        });
};


export const fetchUserTickets = (userId) => {
   
    return httpClient(`ticketing/tickets?assignedUserId=${userId}`)
        .then(response => {
            
            return response;
        })
        .catch(error => {
            console.error(`Error al obtener tickets para userId=${userId}:`, error);
            throw error;
        });
};


export const fetchUserJobs = (userId, status = null) => {
    let endpoint = `scheduling/jobs?assignedUserId=${userId}`;
  
    if (status !== null) {
      endpoint += `&statuses[]=${status}`;
    }
  
    return httpClient(endpoint)
      .then(response => {
        return response;
      })
      .catch(error => {
        console.error(`Error al obtener trabajos para userId=${userId} y status=${status}:`, error);
        throw error;
      });
  };


// Para obtener un adjunto específico
export const getAttachment = (attachmentId) => {
  return httpClient(`scheduling/jobs/attachments/${attachmentId}`);
};

// Para obtener todos los adjuntos
export const getAllAttachments = () => {
  return httpClient('scheduling/jobs/attachments');
};

// Para actualizar un adjunto
export const updateAttachment = (attachmentId, attachmentData) => {
  return httpClient(`scheduling/jobs/attachments/${attachmentId}`, {
      method: 'PATCH',
      body: JSON.stringify(attachmentData)
  });
};

// Para eliminar un adjunto
export const deleteAttachment = (attachmentId) => {
  return httpClient(`scheduling/jobs/attachments/${attachmentId}`, {
      method: 'DELETE'
  });
};

// Para obtener un comentario específico
export const getComment = (commentId) => {
  return httpClient(`scheduling/jobs/comments/${commentId}`);
};

// Para obtener todos los comentarios
export const getAllComments = () => {
  return httpClient('scheduling/jobs/comments');
};

// Para actualizar un comentario
export const updateComment = (commentId, commentData) => {
  return httpClient(`scheduling/jobs/comments/${commentId}`, {
      method: 'PATCH',
      body: JSON.stringify(commentData)
  });
};

// Para eliminar un comentario
export const deleteComment = (commentId) => {
  return httpClient(`scheduling/jobs/comments/${commentId}`, {
      method: 'DELETE'
  });
};


// apiService.js
export const getTasks = (jobId) => {
  return httpClient(`scheduling/jobs/${jobId}`);
};

export const getAllTasks = (jobId) => {
  return httpClient(`scheduling/jobs/tasks?jobId=${jobId}`);
};



export const updateTask = (taskId, taskData) => {
  return httpClient(`scheduling/jobs/tasks/${taskId}`, {
      method: 'PATCH',
      body: JSON.stringify(taskData)
  });
};

export const deleteTask = (taskId) => {
  return httpClient(`scheduling/jobs/tasks/${taskId}`, {
      method: 'DELETE'
  });
};



export const updateJobStatus = (jobId, status) => {
  return httpClient(`scheduling/jobs/${jobId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: { status: status }
  });
};


export const addJob = async (jobData) => {
  return httpClient(`scheduling/jobs`, {
    method: 'POST',
    body: jobData // Asegúrate de que jobData sea un objeto con los campos necesarios para crear un trabajo
  });
};

// Agregar un nuevo comentario
export const addComment = async (commentData) => {
  return httpClient(`scheduling/jobs/comments`, {
    method: 'POST',
    body: commentData // asegúrate de que commentData sea un objeto con los campos necesarios
  });
};

// Agregar un nuevo archivo adjunto
export const addAttachment = (jobId, filename, file) => {
  return httpClient('scheduling/jobs/attachments', {
    method: 'POST',
    body: JSON.stringify({ jobId, filename, file })
  });
};

// Obtener comentarios de un trabajo específico
export const getJobComments = (jobId) => {
  return httpClient(`scheduling/jobs/comments?jobId=${jobId}`);
};

// Obtener archivos adjuntos de un trabajo específico
export const getJobAttachments = (jobId) => {
  return httpClient(`scheduling/jobs/attachments?jobId=${jobId}`);
};


export const obtenerUrlAdjunto = (adjuntoId) => {
  // Retorna la URL completa del archivo adjunto
  return httpClient(`scheduling/jobs/attachments/${adjuntoId}/file`);
};

export const getClienteDetalles = (clienteId) => {
  return httpClient(`clients/${clienteId}`)
    .then(response => {
      return response; // Retorna los detalles del cliente
    })
    .catch(error => {
      console.error("Error al obtener detalles del cliente:", error);
      throw error;
    });
};

export const updateAttribute = (serviceId, attributeData) => {
  const requestBody = attributeData.map(attribute => ({
    customAttributeId: attribute.customAttributeId,
    value: attribute.value
  }));

  return httpClient(`clients/services/${serviceId}`, {
    method: 'PATCH',
    body: JSON.stringify({ attributes: requestBody })
  })
  .then(response => {
    return response;
  })
  .catch(error => {
    console.error("Error al actualizar el atributo:", error);
    throw error;
  });
};


export const obtenerDatosDelServicio = (serviceId) => {
  return httpClient(`clients/services/${serviceId}`)
    .then(response => {
      // Verificar si la propiedad 'attributes' existe antes de acceder a ella
      const attributes = response?.attributes;
      if (attributes) {
        // Utilizar el array de atributos para inicializar el estado
        const attributeData = attributes.reduce((acc, attribute) => {
          acc[attribute.key] = attribute.value || ''; // Inicializa cada atributo con una cadena vacía
          return acc;
        }, {});
        
        // Devolver los datos del servicio y los atributos mapeados
        return { serviceData: response, attributeData: attributeData };
      } else {
        // Manejar el caso donde 'attributes' no está definido
        console.error("La propiedad 'attributes' no está definida en la respuesta.");
        throw new Error("La propiedad 'attributes' no está definida en la respuesta.");
      }
    })
    .catch(error => {
      console.error("Error al obtener los datos del servicio:", error);
      throw new Error('Error al obtener los datos del servicio: ' + error.message);
    });
};







export const getClienteService = (clienteId) => {
  return httpClient(`clients/services?clientId=${clienteId}`)
    .then(response => {
      return response; // Retorna los detalles del Servicio
    })
    .catch(error => {
      console.error("Error al obtener detalles del Servicio:", error);
      throw error;
    });
};
