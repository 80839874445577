// theme.js
import { createTheme } from '@mui/material/styles';
import { blue, deepOrange, grey } from '@mui/material/colors';

// Tema claro
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: blue,
    secondary: deepOrange,
    text: { hint: 'rgba(0, 0, 0, 0.38)' },
    background: {
        default: '#fff', // Fondo blanco para el tema claro
        paper: '#f5f5f5',
      },

  },
  // ... otras personalizaciones
});

// Tema oscuro
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: grey,
    secondary: deepOrange,
    text: { hint: 'rgba(0, 0, 0, 0.38)' },
    background: {
        default: '#303030', // Fondo oscuro para el tema oscuro
        paper: '#424242',
      },

  },
  // ... otras personalizaciones
});

// Función para obtener el tema basado en la preferencia del usuario
export const getTheme = (darkMode) => darkMode ? darkTheme : lightTheme;